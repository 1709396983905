import { Button, Card, Col, Collapse, Divider, Row, Typography } from "antd";
import noDigital from "../../../images/no-digital-card.jpg";
const { Panel } = Collapse;

const Dependent = ({ policy }) => {
  return (
    <>
      <Collapse accordion bordered={false}>
        {policy?.dependents &&
          policy?.dependents.map(
            (dependent, index) => (
              console.log(dependent),
              (
                <Panel
                  header={`Dependent ${index + 1} - ${
                    dependent?.dependent_full_name
                  }`}
                  key={index}
                >
                  <Card>
                    <div className="flex justify-between items-center mb-4 gap-3">
                      <Typography
                        className="text-xl font-semibold"
                        align={"left"}
                      >
                        Policy Detail
                      </Typography>
                    </div>
                    <Divider />
                    <div className="w-full">
                      <img
                        src={
                          dependent?.policy_card_doc
                            ? dependent?.policy_card_doc
                            : noDigital
                        }
                        alt="no-digital-card"
                        className="w-[200px] h-[120px] mb-[14px]"
                      />
                      <div className="flex mb-8">
                        <Row gutter={[40, 16]}>
                          <Col
                            className="gutter-row flex items-start justify-start"
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <div className="text-base font-medium text-black w-[250px]">
                              Unique ID :{" "}
                            </div>
                            <div className="text-base">
                              {dependent?.policy_id}
                            </div>
                          </Col>

                          <Col
                            className="gutter-row flex items-start justify-start"
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            {/* <div className="text-base font-medium text-black w-[250px]">
                          Digital card of policy :{" "}
                        </div>
                        <div className="text-base">
                          {dependent?.digital_card
                            ? dependent?.digital_card
                            : "-"}
                        </div> */}
                          </Col>
                          <Col
                            className="gutter-row flex items-start justify-start"
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <div className="text-base font-medium text-black w-[250px]">
                              Gender :{" "}
                            </div>
                            <div className="text-base capitalize">
                              {dependent?.dependent_gender}
                            </div>
                          </Col>
                          <Col
                            className="gutter-row flex items-start justify-start"
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <div className="text-base font-medium text-black w-[250px]">
                              Dependent Name :{" "}
                            </div>
                            <div className="text-base">
                              {dependent?.dependent_full_name}
                            </div>
                          </Col>
                          <Col
                            className="gutter-row flex items-start justify-start"
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <div className="text-base font-medium text-black w-[250px]">
                              Relationship to policyholder :{" "}
                            </div>
                            <div className="text-base">
                              {dependent?.dependent_type === "spouse"
                                ? "Spouse"
                                : "Child"}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </Panel>
              )
            )
          )}
      </Collapse>
    </>
  );
};

export default Dependent;
