import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LaptopOutlined,
  ContainerOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { checkPermission } from "../../Helpers/Functions";
import { getSocket, initializeSocket } from "../../Socket/socket";
import addDeleteGetLocalStorage from "../../Axios/addDeleteGetLocalStorage";
import { storageKeys } from "../../Axios/Enum";
import { jwtDecode } from "jwt-decode";
import { chatMessageCount } from "../../Axios/services/OpenChat";
import { useDispatch, useSelector } from "react-redux";
import { changeReadchatMessage } from "../../Redux/reducers/readchatMessage";
import { changeAgentReadchatMessage } from "../../Redux/reducers/readchatAgentMessage";
import { getUnreadCount } from "../../Axios/services/InsuranceAgentChat";
import { changeTrigger } from "../../Redux/reducers/trigger";
const { Sider } = Layout;

export const menu = [
  {
    key: "manage_client",
    icon: <UsergroupAddOutlined />,
    label: "Manage Clients",
    link: "/client-management",
    hasPermission: false,
  },
  {
    key: "manage_request",
    icon: <LaptopOutlined />,
    label: "Request Management",
    link: "/request-management",
    hasPermission: false,
  },
  {
    key: "live_chat",
    icon: <ContainerOutlined />,
    label: "Live Chat",
    link: "/live-chat",
    hasPermission: false,
  },
  {
    key: "sub_admin",
    icon: <UserSwitchOutlined />,
    label: "Manage Sub Admins",
    link: "/manage-sub-admin",
    hasPermission: false,
  },
  {
    key: "role_permission",
    icon: <UsergroupAddOutlined />,
    label: "Roles & Permissions",
    link: "/roles-and-permissions",
    hasPermission: false,
  },
  {
    key: "cms",
    icon: <UsergroupAddOutlined />,
    label: "CMS",
    link: "/cms",
    hasPermission: true,
  },
  {
    key: "message_sales",
    icon: <UsergroupAddOutlined />,
    label: "Insurance Chats",
    link: "/insurance-agent-view",
    hasPermission: false,
  },
  {
    key: "account_settings",
    icon: <SettingOutlined />,
    label: "Account Settings",
    link: "/account-settings",
    hasPermission: true,
  },
];
const LeftSidebar = () => {
  let token = addDeleteGetLocalStorage(
    storageKeys.ADMIN_TOKEN,
    {},
    "get",
    "single"
  );
  token = jwtDecode(token);
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const readchatMessage = useSelector((state) => state?.readchatMessage);
  const readchatAgentMessage = useSelector(
    (state) => state?.readchatAgentMessage
  );
  const [collapsed, setCollapsed] = useState(false);
  const [count, setCount] = useState(0);
  const [agentCount, setAgentCount] = useState(0);
  const dispatch = useDispatch();
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleChatMessageCount = async () => {
    try {
      let res = await chatMessageCount();
      if (res?.ack) {
        setCount(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(changeReadchatMessage(false));
  };

  const handleGetUnreadCount = async () => {
    try {
      let res = await getUnreadCount();
      if (res?.ack) {
        setAgentCount(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(changeAgentReadchatMessage(false));
  };

  useEffect(() => {
    handleChatMessageCount();
    const selectedPath = menu.find((item) => item.link == location.pathname);
    setSelectedMenu(selectedPath?.key);
  }, []);

  useEffect(() => {
   // if (readchatMessage?.value == true) {
      handleChatMessageCount();
  //  }
  }, [readchatMessage]);

  useEffect(() => {
    if (readchatAgentMessage?.value == true) {
      handleGetUnreadCount();
    }
  }, [readchatAgentMessage]);

  useEffect(() => {
    let socket = getSocket();

    const readCount = async () => {
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }

      const chatReadCountListener = (data) => {
        setCount(data?.readCount);
      };

      const chatAgentReadCountListener = (data) => {
        dispatch(changeTrigger("countValueChanged"));
        setAgentCount(data?.readCount);
      };

      socket?.on(`chat-read-count-${token?.user_id}`, chatReadCountListener);
      socket?.on(
        `agent-chat-read-count-${token?.user_id}`,
        chatAgentReadCountListener
      );
      socket?.on("closed-chat-toast", (data) => {
        if (data) {
          handleChatMessageCount();
        }
      })
    };

    readCount();

    return () => {
      if (socket) {
        socket?.off(`chat-read-count-${token?.user_id}`);
        socket?.off(`agent-chat-read-count-${token?.user_id}`);
        socket?.off("closed-chat-toast");
      }
    };
  }, []);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      width={250}
    >
      <div
        className={`logo flex justify-center h-[56px] my-4 transition-opacity duration-500`}
      >
        {collapsed ? (
          <img src="/favicon.png" alt="favicon" className="" />
        ) : (
          <img
            src="/logo.svg"
            alt="logo"
            style={{ filter: "brightness(0) invert(1)" }}
            className="w-[160px]"
          />
        )}
      </div>
      <Menu theme="dark" selectedKeys={[selectedMenu]} mode="inline">
        {menu &&
          menu.map((item) => {
            if (item?.key == "cms" && token?.role !== "super_admin") {
              return null;
            }
            if(item?.key == "message_sales" && token?.role === "super_admin"){
              return null;
            }
            if (
              checkPermission(item.key, "view") ||
              item.hasPermission == true
            ) {
              return (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  onClick={(e) => setSelectedMenu(e?.key)}
                >
                  <Link to={item.link}>
                    {item.label}{" "}
                    {item?.label == "Live Chat"
                      ? count
                        ? `(${count})`
                        : ""
                      : ""}
                    {item?.label == "Insurance Chats"
                      ? agentCount
                        ? `(${agentCount})`
                        : ""
                      : ""}
                  </Link>
                </Menu.Item>
              );
            }
          })}
      </Menu>
      <div className="absolute top-[14px] right-[-42px] text-black text-xl">
        {collapsed ? (
          <MenuUnfoldOutlined
            className="toggle-icon"
            onClick={toggleCollapsed}
          />
        ) : (
          <MenuFoldOutlined className="toggle-icon" onClick={toggleCollapsed} />
        )}
      </div>
    </Sider>
  );
};

export default LeftSidebar;
