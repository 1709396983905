import { Button, Card, Divider, Form, Input, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { DeleteUser } from "../../Axios/services/DeleteUser";
import { toast } from "react-toastify";
import { changeLoader } from "../../Redux/reducers/loader";
import { validateEmail } from "../../Axios/custom";
import { useDispatch } from "react-redux";

const DeleteAccount = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [nameError, setNameError] = useState("");
  const [contactErrorText, setContactErrorText] = useState("");
  const dispatch = useDispatch();

  const handleError = () => {
    let error = false;
    if (email)
      if (!validateEmail(email)) {
        setEmailErrorText("Please enter valid email");
        error = true;
      }
    if (name && name.length > 100) {
      setNameError("Maximum 100 characters allowed");
      error = true;
    }

    if (name && RegExp(/^[a-zA-Z ]*$/).test(name) === false) {
      setNameError("Please enter valid name");
      error = true;
    }

    if ((mobile && mobile?.length < 10) || (mobile && mobile?.length > 10)) {
      setContactErrorText("Invalid contact number. Maximum 10 digits allowed");
      error = true;
    }
    return error;
  };

  const handleDeleteAccount = async () => {
    dispatch(changeLoader(true));
    if (!handleError()) {
      try {
        let res = await DeleteUser({ name, mobile, email, reason });
        if(res?.ack){
          setIsDisabled(true);
        }
        toast[res.ack ? "success" : "error"](res.message, {
          limit: 1,
          toastId: "forgotPassword" + (res.ack ? "Success" : "Error"),
        });
        dispatch(changeLoader(false));
      } catch (err) {
        console.log(err);
      }
    }

    dispatch(changeLoader(false));
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <Typography className="text-xl font-semibold mb-4" align="left">
        Delete Account Request
      </Typography>
      <Card className="w-full max-w-lg p-4">
        <div className="min-h-[calc(100vh_-_305px)] flex flex-col justify-center">
          <Form
            name="raise request"
            layout="vertical"
            style={{
              maxWidth: 500,
              width: "100%",
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <Form.Item label="Name" name="name">
              <Input
                size="large"
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError("");
                }}
              />
              <span className="text-red-500">{nameError}</span>
              {/* <span className="text-red-500">Please Enter a valid name</span> */}
            </Form.Item>
            <Form.Item label="Mobile Number" name="Mobile Number">
              <Input
                size="large"
                // type="number"
                onChange={(e) => {
                  setMobile(e.target.value.trim());
                  setContactErrorText("");
                }}
              />
              <span className="text-red-500">{contactErrorText}</span>
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input
                size="large"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailErrorText("");
                }}
              />
              <span className="text-red-500">{emailErrorText}</span>
            </Form.Item>
            <Form.Item label="Reason" name="reason">
              <TextArea
                size="large"
                onChange={(e) => setReason(e.target.value)}
              />
              {/* <span className="text-red-500">Please Enter a valid reason</span> */}
            </Form.Item>
          </Form>
        </div>
        <div className="flex justify-end gap-3">
          <Button
            type="primary"
            onClick={handleDeleteAccount}
            disabled={!name || !mobile || !email || !reason || isDisabled}
          >
            Request
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default DeleteAccount;
