import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Typography,
  Select,
  Upload,
} from "antd";
import {
  LeftOutlined,
  UploadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import {
  CreateRaiseRequest,
  DeleteDocument,
  FileUpload,
  GetAllPolicy,
  GetClients,
  GetRequestDetail,
  UpdateRaiseRequest,
} from "../../Axios/services/ManageRequest";
import { changeLoader } from "../../Redux/reducers/loader";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ImageCropper from "../../Modal/Cropper";
import { getBase64 } from "../../Helpers/Functions";
import fileUpload from "../../Axios/UploadFile";
import fileAzurUpload from "../../Axios/UploadFile";
const { Option } = Select;
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const MAX_IMAGES = 10;

const RaiseRequest = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [clients, setClients] = React.useState([]);
  const [policys, setPolicys] = React.useState([]);
  const [description, setDescription] = React.useState("");
  const [searchValue, setSearchValue] = useState("");
  const [clientId, setClientId] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [cropperImage, setCropperImage] = useState(null);
  const [policyId, setPolicyId] = useState("");
  const [cropper, setCropper] = useState(null);
  const [cropType, setCropType] = useState(null);
  const [clientErrorText, setClientErrorText] = useState("");
  const [agent_id, setAgentId] = useState(null);
  const [policyErrorText, setPolicyErrorText] = useState("");
  const [descriptionErrorText, setDescriptionErrorText] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [requestId, setRequestId] = useState(null);

  const dispatch = useDispatch();
  const handleSearch = (e) => {
    setSearchValue(e.target.value.trimStart());
  };

  const handleError = () => {
    let error = false;
    if (searchValue && searchValue.length > 100) {
      setClientErrorText("Name must be at maximum 100 characters");
      error = true;
    }
    if (policyId === null) {
      setPolicyErrorText("Please select policy");
      error = true;
    }
    if (!policys) {
      setPolicyErrorText("Please select policy");
      error = true;
    }
    // if (description === "") {
    //   console.log("This is the Desc", description)
    //   setDescriptionErrorText("Please enter description");
    //   error = true;
    // }
    if (description && description.length > 1000) {
      setDescriptionErrorText(
        "Description must be at maximum 1000 characters."
      );
      error = true;
    }
    return error;
  };

  const handleRaiseRequest = async (type) => {
    dispatch(changeLoader(true));
    if (handleError()) {
      dispatch(changeLoader(false));
      return;
    }
    try {
      let response;
      if (type === "edit") {
        response = await UpdateRaiseRequest({
          request_id: Number(slug),
          request_description: description,
          documents: uploadedImages
            ?.filter((image) => !image?.id)
            ?.map((image) => image?.document),
        });
      } else {
        response = await CreateRaiseRequest({
          request_client: clientId,
          request_policy: policyId,
          request_agent: agent_id,
          request_description: description,
          documents: uploadedImages.map((image) => image?.document),
        });
      }
      if (response.ack) {
        navigate("/request-management");
      }
      toast[response.ack ? "success" : "error"](response.message, {
        limit: 1,
        toastId: "forgotPassword" + (response.ack ? "Success" : "Error"),
      });
      dispatch(changeLoader(false));
    } catch (err) {
      dispatch(changeLoader(false));
      console.log(err);
    }
  };

  const filteredClients = clients.filter(
    (client) =>
      client.full_name.toLowerCase().includes(searchValue.toLowerCase()) ||
      client.email.toLowerCase().includes(searchValue.toLowerCase())
  );
  const getAllClients = async () => {
    slug && dispatch(changeLoader(true));
    try {
      let parms = {};
      if (searchValue) {
        parms["search"] = searchValue;
      }
      const response = await GetClients(parms);
      if (response.ack) {
        setClients(response.data);
      }
      slug && dispatch(changeLoader(false));
    } catch (err) {
      slug && dispatch(changeLoader(false));
      console.log(err);
    }
  };

  const handleImageUpload = async (event, type) => {
    dispatch(changeLoader(true));
    const files = event.target.files;
    if (!files || files.length === 0) {
      dispatch(changeLoader(false));
      throw new Error("No files selected");
    }

    if (type !== "edit" && uploadedImages.length + files.length > MAX_IMAGES) {
      dispatch(changeLoader(false));
      toast.error(`Cannot upload more than ${MAX_IMAGES} attachments`, {
        limit: 1,
        toastId: "imageError",
      });
      return;
    }
    for (const file of files) {
      if (file.size > MAX_FILE_SIZE) {
        dispatch(changeLoader(false));
        toast.error("File size too large", {
          limit: 1,
          toastId: "imageError",
        });
        return;
      }

      if (
        !["image/jpeg", "image/jpg", "image/png", "application/pdf"].includes(
          file.type
        )
      ) {
        dispatch(changeLoader(false));
        toast.error("File type not supported", {
          limit: 1,
          toastId: "imageError",
        });
        return;
      }
    }
    for (let i = 0; i < files?.length; i++) {
      if (files[i]) {
        const formdata = new FormData();
        formdata.append("file", files[i]);
        const fileUpload = await fileAzurUpload(formdata);
        if (fileUpload) {
          setUploadedImages((prev) => {
            return [...prev, { document: fileUpload?.data?.url }];
          });
          toast.success(fileUpload.message, {
            limit: 1,
            toastId: "imageUploadSuccess",
          });
        } else {
          toast.error(fileUpload.message, {
            limit: 1,
            toastId: "imageUploadError",
          });
        }
      }
    }
    dispatch(changeLoader(false));
  };

  const handleImageRemove = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleDeleteImage = async (index, type, id) => {
    if (type === "create" || !id) {
      handleImageRemove(index);
      return;
    }
    try {
      let res = await DeleteDocument({ document_id: id });
      if (res?.ack) {
        handleImageRemove(index);
      }
      toast[res.ack ? "success" : "error"](res.message, {
        limit: 1,
        toastId: "forgotPassword" + (res.ack ? "Success" : "Error"),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const debouncedFetchData = useCallback(
    debounce((index, type, id) => {
      handleDeleteImage(index, type, id);
      handleActionRequest(type);
    }, 500),
    []
  );

  const handleActionRequest = (type) => {
    handleRaiseRequest(type);
  };

  const handleClickAction = (index, type, id) => {
    debouncedFetchData(index, type, id);
  };

  const getAllPolicy = async (clientId) => {
    slug && dispatch(changeLoader(true));
    try {
      const response = await GetAllPolicy(clientId);
      if (response.ack) {
        setPolicys(response?.data);
        setAgentId(response?.data[0]?.insurance?.id);
      }
      slug && dispatch(changeLoader(false));
    } catch (err) {
      slug && dispatch(changeLoader(false));
      console.log(err);
    }
  };

  const handleGetRequestDetail = async () => {
    slug && dispatch(changeLoader(true));
    try {
      let responce = await GetRequestDetail(slug);
      if (responce?.ack) {
        setRequestId(responce?.data?.request_id);
        setClientId(responce?.data?.client?.id);
        setPolicyId(responce?.data?.policy?.id);
        setAgentId(responce?.data?.request_agent);
        setDescription(responce?.data?.request_description);
        // let images = responce?.data?.request_documents?.map(
        //   (image) => image?.document
        // );
        setUploadedImages(responce?.data?.request_documents);
      }
      slug && dispatch(changeLoader(false));
    } catch (error) {
      slug && dispatch(changeLoader(false));
      console.log(error);
    }
  };

  useEffect(() => {
    if (slug) {
      handleGetRequestDetail();
    }
  }, []);

  useEffect(() => {
    if (clientId && slug) {
      getAllPolicy(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    const getSearchData = setTimeout(() => {
      getAllClients();
    }, 500);

    return () => clearTimeout(getSearchData);
  }, [searchValue]);

  return (
    <Card>
      <Typography className="text-xl font-semibold" align={"left"}>
        <LeftOutlined className="mr-2" onClick={() => navigate(-1)} />
        {slug ? "Update Raised Ticket" : "Raise Request"}
      </Typography>
      <Divider />
      <div className="min-h-[calc(100vh_-_305px)]">
        <Form
          name="raise request"
          layout="vertical"
          style={{
            maxWidth: 500,
            width: "100%",
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          className=""
        >
          <Form.Item
            label={
              <>
                Client
                <span style={{ color: "red" }}> *</span>
              </>
            }
            name="client"
          >
            <Select
              size="large"
              open={dropdownOpen}
              onDropdownVisibleChange={setDropdownOpen}
              onChange={(value) => {
                getAllPolicy(value);
                setClientId(value);
                setPolicyId(null);
                setAgentId(null);
                setPolicyErrorText("");
                setClientErrorText("");
              }}
              disabled={slug ? true : false}
              value={clientId}
              dropdownRender={(menu) => (
                <div>
                  <Input
                    style={{ marginBottom: "8px" }}
                    type="search"
                    placeholder="Search for a Client"
                    value={searchValue}
                    onChange={handleSearch}
                    autoFocus
                  />
                  {menu}
                </div>
              )}
              onSelect={() => setDropdownOpen(false)}
              filterOption={false} // We are filtering manually
            >
              {filteredClients?.length > 0 &&
                filteredClients.map((client) => (
                  <Option key={client.id} value={client.id}>
                    {client.full_name} ({client.email})
                  </Option>
                ))}
            </Select>
            <span className="text-red-500">{clientErrorText}</span>
          </Form.Item>
          <Form.Item
            label={
              <>
                Policy
                <span style={{ color: "red" }}> *</span>
              </>
            }
            name="Policy"
          >
            <Select
              size="large"
              onChange={(value) => {
                setPolicyId(value);
                setPolicyErrorText("");
              }}
              value={policyId}
              disabled={slug ? true : false}
              options={
                policys?.length > 0 &&
                policys?.map((policy) => ({
                  value: policy.id,
                  label: policy.policy_name,
                }))
              }
              notFoundContent={
                <div style={{ textAlign: "center" }}>No Policy found</div>
              }
            />
            <span className="text-red-500">{policyErrorText}</span>
          </Form.Item>
          <Form.Item label="Agent" name="Agent">
            {policys?.length > 0
              ? policys.map((policy) =>
                  policy ? (
                    <div key={policy?.insurance?.agent_email}>
                      {policy?.insurance?.agent_name} -{" "}
                      {policy?.insurance?.agent_email}
                    </div>
                  ) : (
                    "-to be displayed based on Client selection-"
                  )
                )
              : "-to be displayed based on Client selection-"}
          </Form.Item>
          <Form.Item
            label={
              <>
                Description
                <span style={{ color: "red" }}> *</span>
              </>
            }
            name="Description"
            className="col-span-2"
          >
            <TextArea
              size="large"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setDescriptionErrorText("");
              }}
            />
            <span className="text-red-500">{descriptionErrorText}</span>
          </Form.Item>
          <Form.Item label="Attachment" name="Attachment">
            <div className="upload-container flex items-start justify-start gap-4">
              {uploadedImages?.length === 0 && (
                <label htmlFor="file-upload" className="custom-file-upload">
                  <UploadOutlined /> Click to Upload
                  <input
                    id="file-upload"
                    type="file"
                    onChange={(event) =>
                      slug
                        ? handleImageUpload(event, "edit")
                        : handleImageUpload(event, "create")
                    }
                    multiple
                  />
                </label>
              )}

              {uploadedImages?.length > 0 &&
                uploadedImages?.map((image, index) => (
                  <div key={index} className="flex flex-wrap gap-4">
                    <div key={index} className="image-container">
                      <img
                        src={
                          !image?.document?.includes(".pdf")
                            ? image?.document
                            : "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                        }
                        alt={`Uploaded ${index}`}
                        className="uploaded-image"
                      />
                      <CloseCircleOutlined
                        className="remove-icon"
                        onClick={() => {
                          let type = slug ? "edit" : "create";
                          handleClickAction(index, type, image?.id);
                        }}
                      />
                    </div>
                  </div>
                ))}
              {!(
                uploadedImages?.length >= 10 || uploadedImages?.length === 0
              ) ? (
                <>
                  <label htmlFor="file-upload" className="custom-file-upload">
                    <UploadOutlined className="mx-0 mb-2 text-[24px]" />
                    <span>Click to Upload</span>
                    <input
                      id="file-upload"
                      type="file"
                      onChange={handleImageUpload}
                      multiple
                    />
                  </label>
                </>
              ) : null}
            </div>
          </Form.Item>
        </Form>
      </div>
      <Divider />
      <div className="flex justify-end gap-3">
        {/* <Button>Cancel</Button> */}
        <Button
          type="primary"
          onClick={() => handleActionRequest(slug ? "edit" : "create")}
          disabled={
            clients === null ||
            policys === null ||
            description === "" ||
            policyId === null
          }
        >
          {slug ? "Update Raised Ticket" : "Raise Request"}
        </Button>
      </div>

      {/* {cropper ? (
        <ImageCropper
          cropperImage={cropperImage}
          closepopup={() => {
            setCropper(false);
            if (document.getElementById("file-upload")) {
              document.getElementById("file-upload").value = "";
            }
          }}
          setCropImage={async(croppedImage) => {
            const fileUpload = await FileUpload({ base64File: croppedImage.croppedImage})
            if(fileUpload.ack){
              setUploadedImages([...uploadedImages, fileUpload?.data]);
              toast.success(fileUpload?.message, {
                limit: 1,
                toastId: "imageUploadSuccess",
              })
            }else{
              toast.error(fileUpload?.message, {
                limit: 1,
                toastId: "imageUploadError",
              })
            }
            setCropper(false);
            setCropperImage("");
          }}
        />
      ) : null} */}
    </Card>
  );
};

export default RaiseRequest;
