import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  List,
  Pagination,
  Row,
  Tooltip,
} from "antd";
import SortingArrow from "../../../Common/SortingArrow";
import {
  MessageOutlined,
  SendOutlined,
  CloseOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import CloseChat from "../../../Modal/CloseChat";
import DateHelper from "../../../Helpers/lib/DateHelper";
import {
  getchatRoom,
  getChatRoomMessage,
  sendMessage,
  closeChat,
  getChatMessageRead,
} from "../../../Axios/services/OpenChat";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../Redux/reducers/loader";
import { getSocket, initializeSocket } from "../../../Socket/socket";
import addDeleteGetLocalStorage from "../../../Axios/addDeleteGetLocalStorage";
import { storageKeys } from "../../../Axios/Enum";
import { jwtDecode } from "jwt-decode";
import EmptyComponent from "../../Empty";
import { toast } from "react-toastify";
import { changeReadchatMessage } from "../../../Redux/reducers/readchatMessage";
import { changeTrigger } from "../../../Redux/reducers/trigger";

const OpenChat = ({
  chatQueue,
  setChatQueue,
  handleGetAllchatqueue,
  openChatCount,
  page,
  limit,
  pageCount,
  tabvalue,
  itemTotal,
  handlePageChange,
  setLimit,
  clientId,
}) => {
  const [message, setMessage] = useState("");
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chatRoomId, setChatRoomId] = useState(null);
  const dispatch = useDispatch();
  const chatContainerRef = useRef(null);
  const trigger = useSelector((state) => state.trigger.value);
  const token = addDeleteGetLocalStorage(
    storageKeys.ADMIN_TOKEN,
    {},
    "get",
    "single"
  );
  const user_id = token ? jwtDecode(token).user_id : 0;
  const [isViewChatLogModalOpen, setIsViewChatLogModalOpen] = useState(false);
  let [chatRoomResponces, setChatRoomResponces] = useState({
    chatRoomData: {},
    chatMessagesData: [],
  });

  const scrollToBottom = () => {
    chatContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleViewChatLogOk = async () => {
    let responce = await closeChat(chatRoomId);
    if (responce.ack) {
      setIsViewChatLogModalOpen(false);
      handleGetAllchatqueue();
      setIsChatOpen(false);
    }
    toast[responce.ack ? "success" : "error"](responce.message, {
      limit: 1,
      toastId: "forgotPassword" + (responce.ack ? "Success" : "Error"),
    });
  };
  const handleCloseClick = () => {
    setChatRoomId(null);
    setIsChatOpen(false);
    if (openChatCount?.chat_room_id == chatRoomId) {
      zeroOpenChatCount();
    }
  };
  const handleViewChatLogCancel = () => {
    setIsViewChatLogModalOpen(false);
  };

  const zeroOpenChatCount = () => {
    let arr = Array.isArray(chatQueue?.rows) ? [...chatQueue.rows] : [];
    let index = arr.findIndex(
      (item) => item?.id == openChatCount?.chat_room_id
    );
    if (index === -1) return;
    arr[index].total_messages = 0;
    setChatQueue({ ...chatQueue, rows: arr });
  };

  const handleChatClick = async (id) => {
    dispatch(changeLoader(true));
    let [chatroomResponce, chatMessagesResponce, chatMessageReadResponce] =
      await Promise.all([
        getchatRoom(id),
        getChatRoomMessage(id),
        getChatMessageRead(id),
      ]);
    if (
      chatroomResponce.ack &&
      chatMessagesResponce.ack &&
      chatMessageReadResponce.ack
    ) {
      dispatch(changeLoader(false));
      setIsChatOpen(true);
      setChatRoomResponces({
        chatRoomData: chatroomResponce?.data,
        chatMessagesData: chatMessagesResponce?.data?.rows,
      });
      setTimeout(() => {
        scrollToBottom();
      }, 400);
      dispatch(changeReadchatMessage(true));
      zeroOpenChatCount();
    }
    dispatch(changeLoader(false));
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      let data = {
        chat_room_id: chatRoomId,
        message: message,
        receiver_id: chatRoomResponces?.chatRoomData?.client_id,
      };
      let responce = await sendMessage(data);
      if (responce.ack) {
        setChatRoomResponces((prev) => {
          return {
            ...prev,
            chatMessagesData: [...prev.chatMessagesData, responce?.data],
          };
        });
        setTimeout(() => {
          scrollToBottom();
        }, 400);
        setMessage("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let socket = getSocket();
    const chatMessages = async () => {
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }
      socket?.on(`new-chat-message-${chatRoomId}`, (data) => {
        if (data) {
          socket?.emit("mark-as-read", chatRoomId);
          if (chatRoomId) {
            dispatch(changeReadchatMessage(true));
          }
        }
        if (data?.chat_message?.receiver_id === user_id) {
          setChatRoomResponces((prev) => {
            return {
              ...prev,
              chatMessagesData: [...prev.chatMessagesData, data?.chat_message],
            };
          });
          setTimeout(() => {
            scrollToBottom();
          }, 400);
        }
      });
    };

    chatMessages();

    if (socket) {
      return () => {
        socket?.off(`new-chat-message-${chatRoomId}`);
        socket?.off(`mark-as-read`);
      };
    }
  }, [chatRoomResponces, chatRoomId, tabvalue]);

  useEffect(() => {
    let socket = getSocket();
    const chatClosed = async () => {
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }
      if (chatRoomId) {
        socket?.on(`closed-chat-${chatRoomId}`, (data) => {
          if (data?.chat_room?.id) {
            handleGetAllchatqueue();
            setIsChatOpen(false);
          }
        });
      }
    };

    chatClosed();
    if (socket && chatRoomId) {
      return () => socket?.off(`closed-chat-${chatRoomId}`);
    }
  }, [chatRoomId, tabvalue]);

  useEffect(() => {
    chatQueue &&
      tabvalue == "open" &&
      chatQueue?.rows?.map((item) => {
        if (item?.client_id == clientId) {
          handleChatClick(item?.id);
          setChatRoomId(item?.id);
          setIsChatOpen(true);
        }
      });
  }, [chatQueue, tabvalue]);

  useEffect(() => {
    if (trigger === "countValueChanged" && openChatCount?.chat_room_id) {
      let arr = Array.isArray(chatQueue?.rows) ? [...chatQueue.rows] : [];

      let index = arr.findIndex(
        (item) => item?.id === openChatCount?.chat_room_id
      );

      if (index !== -1) {
        let count = Number(arr[index]?.total_messages) + 1;
        arr[index].total_messages = count;
        setChatQueue({ ...chatQueue, rows: arr });
      }

      dispatch(changeTrigger(""));
    }
  }, [trigger, openChatCount]);

  return (
    <>
      <Row gutter={16}>
        <Col span={isChatOpen ? 12 : 24}>
          <Card>
            <div className="relative overflow-x-auto ">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-3 whitespace-nowrap">
                      Client ID
                      {/* <SortingArrow /> */}
                    </th>
                    <th scope="col" className="px-3 py-3 whitespace-nowrap">
                      Client Name
                      {/* <SortingArrow /> */}
                    </th>
                    <th scope="col" className="px-3 py-3 whitespace-nowrap">
                      Email Address
                      {/* <SortingArrow /> */}
                    </th>
                    <th scope="col" className="px-3 py-3 whitespace-nowrap">
                      Assigned Agent
                      {/* <SortingArrow /> */}
                    </th>
                    <th scope="col" className="px-3 py-3 whitespace-nowrap">
                      Assigned Date & time
                      {/* <SortingArrow /> */}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-center whitespace-nowrap"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {chatQueue?.rows?.length > 0 ? (
                    chatQueue?.rows?.map((item) => (
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top"
                        >
                          {item?.client_id}
                        </th>
                        <td className="px-3 py-4 vertical-top">
                          {item?.client?.full_name}
                        </td>
                        <td className="px-3 py-4 vertical-top">
                          {item?.client?.email}
                        </td>
                        <td className="px-3 py-4 vertical-top">
                          <p className="text-black font-semibold">
                            {item?.agent?.full_name}
                          </p>
                          <p>{item?.agent?.email}</p>
                        </td>
                        <td className="px-3 py-4 vertical-top">
                          {item?.assigned_at
                            ? DateHelper.format(
                                item?.assigned_at,
                                "d/m/Y h:i A"
                              )
                            : "-"}
                        </td>
                        {user_id == item?.agent_id && (
                          <td className="px-3 py-4 text-center vertical-top">
                            <Tooltip title="Chat">
                              <Badge
                                count={item?.total_messages || 0}
                                className="noti-icon cursor-pointer"
                                onClick={() => {
                                  handleChatClick(item?.id);
                                }}
                              >
                                <MessageOutlined
                                  onClick={() => {
                                    handleChatClick(item?.id);
                                    setChatRoomId(item?.id);
                                  }}
                                />
                              </Badge>
                            </Tooltip>
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        <EmptyComponent description="No open chat found" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="text-right mt-4">
              {pageCount > 1 && (
                <div className="flex justify-end mt-4">
                  <Pagination
                    defaultCurrent={page}
                    defaultPageSize={limit}
                    total={itemTotal}
                    onChange={handlePageChange}
                    pageSizeOptions={[10, 25, 50, 100]}
                    onShowSizeChange={(current, size) => {
                      limit = size;
                      setLimit(limit);
                      handleGetAllchatqueue();
                    }}
                  />
                </div>
              )}
            </div>
          </Card>
        </Col>
        {isChatOpen && (
          <Col span={12}>
            <Card>
              <div className="mb-0">
                <div className="w-full pt-1">
                  <div id="messages">
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-semibold capitalize">
                        <CloseOutlined
                          onClick={() => {
                            handleCloseClick();
                          }}
                          className="cursor-pointer"
                        />{" "}
                        <Avatar
                          size={32}
                          icon={
                            chatRoomResponces?.chatRoomData?.client
                              ?.profile_picture ? (
                              <img
                                src={
                                  chatRoomResponces?.chatRoomData?.client
                                    ?.profile_picture
                                }
                                alt="Profile"
                              />
                            ) : (
                              `${chatRoomResponces?.chatRoomData?.client?.full_name
                                ?.split(" ")[0]
                                .charAt(0)
                                .toUpperCase()}${
                                chatRoomResponces?.chatRoomData?.client?.full_name?.split(
                                  " "
                                )[1]
                                  ? chatRoomResponces?.chatRoomData?.client?.full_name
                                      .split(" ")[1]
                                      .charAt(0)
                                      .toUpperCase()
                                  : ""
                              }`
                            )
                          }
                          className="mr-2"
                        />
                        {chatRoomResponces &&
                        chatRoomResponces?.chatRoomData?.client?.full_name
                          ? chatRoomResponces?.chatRoomData?.client?.full_name
                          : "-"}
                      </span>
                      <div>
                        {" "}
                        <Button
                          type="primary"
                          onClick={() => {
                            setIsViewChatLogModalOpen(true);
                          }}
                        >
                          Mark chat as Closed
                        </Button>
                      </div>
                    </div>
                    <Divider />
                    <div className="h-[calc(100vh_-_365px)] overflow-y-auto">
                      {/* <Divider className="!m-0">
                        <span className="text-[12px]">May 29, 2024</span>
                      </Divider> */}
                      {chatRoomResponces?.chatMessagesData?.map(
                        (item, index) => {
                          const isUserMessage = user_id !== item?.receiver_id;

                          return (
                            <div
                              className="mb-4"
                              key={index}
                              ref={chatContainerRef}
                            >
                              <div
                                className={`flex ${
                                  isUserMessage
                                    ? "justify-end"
                                    : "justify-start"
                                } items-center`}
                              >
                                {!isUserMessage && (
                                  <Avatar
                                    size={32}
                                    icon={
                                      chatRoomResponces?.chatRoomData?.client
                                        ?.profile_picture ? (
                                        <img
                                          src={
                                            chatRoomResponces?.chatRoomData
                                              ?.client?.profile_picture
                                          }
                                          alt="Profile"
                                        />
                                      ) : (
                                        `${chatRoomResponces?.chatRoomData?.client?.full_name
                                          ?.split(" ")[0]
                                          .charAt(0)
                                          .toUpperCase()}${
                                          chatRoomResponces?.chatRoomData?.client?.full_name?.split(
                                            " "
                                          )[1]
                                            ? chatRoomResponces?.chatRoomData?.client?.full_name
                                                .split(" ")[1]
                                                .charAt(0)
                                                .toUpperCase()
                                            : ""
                                        }`
                                      )
                                    }
                                    className="mr-2"
                                  />
                                )}
                                <div
                                  className={`w-auto max-w-[80%] min-w-[100px] break-all p-2 pb-4 rounded-lg relative ${
                                    isUserMessage
                                      ? "bg-[#0dbff1] text-white"
                                      : "bg-slate-100"
                                  }`}
                                >
                                  <p className="text-sm mb-2">
                                    {item?.message}
                                  </p>
                                  <span
                                    className={`absolute right-2 bottom-1 text-[10px] ${
                                      isUserMessage
                                        ? "text-white"
                                        : "text-[#a8a8a8]"
                                    }`}
                                  >
                                    {DateHelper.format(
                                      item?.created_at,
                                      "h:i A"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Input */}
                  <form
                    className="flex py-4 border-t border-gray-300"
                    onSubmit={handleSendMessage}
                  >
                    <input
                      id="messageInput"
                      type="text"
                      placeholder="Type your message here..."
                      className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                      value={message}
                      onChange={(e) => setMessage(e.target.value?.trimStart())}
                    />
                    <button
                      type="submit"
                      className={`ml-2 px-[14px] rounded-lg text-white ${
                        message
                          ? "bg-[#0dbff1]"
                          : "bg-gray-400 cursor-not-allowed"
                      }`}
                      disabled={!message} // This disables the button when 'message' is an empty string
                    >
                      <SendOutlined />
                    </button>
                  </form>
                </div>
              </div>
            </Card>
          </Col>
        )}
      </Row>
      <CloseChat
        ConfirmationHeading="Alert"
        ConfirmationParagraph="Are you sure you want to close the chat? This is end the current chat & you cannot reopen it."
        isOpen={isViewChatLogModalOpen}
        onOk={handleViewChatLogOk}
        onCancel={handleViewChatLogCancel}
      />
    </>
  );
};

export default OpenChat;
