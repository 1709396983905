import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCmsStaticPage } from "../../Axios/services/CmsStatic";
const CmsStatic = () => {
  const { slug } = useParams();
  const [responce, setResponce] = useState({});
  const handlegetCmsStaticPage = async () => {
    try {
      let res = await getCmsStaticPage(slug);
      if (res.ack) {
        setResponce(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handlegetCmsStaticPage();
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: responce?.content }}></div>;
};

export default CmsStatic;
